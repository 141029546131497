import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NavController } from '@ionic/angular';

const routes: Routes = [
  { path: '',             loadChildren: './tabs/tabs.module#TabsPageModule' },
  { path: 'product/:id',  loadChildren: './product/product.module#ProductPageModule'},
  { path: 'products',  loadChildren: './products/products.module#ProductsPageModule'},
  { path: 'products/:id',  loadChildren: './products/products.module#ProductsPageModule'},
  { path: 'payment/:id',  loadChildren: './payment/payment.module#PaymentPageModule'},
  { path: 'category',     loadChildren: './category/category.module#categoryPageModule'},
  { path: 'cart',         loadChildren: './cart/cart.module#cartPageModule'},
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'view-cart', loadChildren: './view-cart/view-cart.module#ViewCartPageModule' },
  { path: 'order', loadChildren: './order/order.module#OrderPageModule' },
  { path: 'payment/:id', loadChildren: './payment/payment.module#PaymentPageModule' },
  { path: 'orders/:order_status', loadChildren: './orders/orders.module#OrdersPageModule' },
  { path: 'order/:id', loadChildren: './order/order.module#OrderPageModule' },
  { path: 'payment-qr/:id', loadChildren: './payment-qr/payment-qr.module#PaymentQrPageModule' },
  { path: 'register', loadChildren: './register/register.module#RegisterPageModule' },
  { path: 'profile', loadChildren: './profile/profile.module#profilePageModule' },
  { path: 'members', loadChildren: './members/members.module#MembersPageModule' },
  { path: 'payments', loadChildren: './payments/payments.module#PaymentsPageModule' },
  { path: 'comm', loadChildren: './comm/comm.module#CommPageModule' },
  { path: 'tools', loadChildren: './tools/tools.module#ToolsPageModule' },
  { path: 'pay', loadChildren: './pay/pay.module#PayPageModule' },
  { path: 'pay/:order_id/:ref_no/:order_sum_price', loadChildren: './pay/pay.module#PayPageModule' },
  { path: 'regis-ok', loadChildren: './regis-ok/regis-ok.module#RegisOkPageModule' },
  { path: 'productall', loadChildren: './productall/productall.module#ProductallPageModule' },
  { path: 'productall/:keyword', loadChildren: './productall/productall.module#ProductallPageModule' },
  { path: 'gift', loadChildren: './gift/gift.module#GiftPageModule' },
  { path: 'gift-des/:id', loadChildren: './gift-des/gift-des.module#GiftDesPageModule' },
  { path: 'gift-report', loadChildren: './gift-report/gift-report.module#GiftReportPageModule' },
  { path: 'plan', loadChildren: './plan/plan.module#PlanPageModule' },
  { path: 'doc', loadChildren: './doc/doc.module#DocPageModule' },
  { path: 'avatar', loadChildren: './avatar/avatar.module#AvatarPageModule' },
  { path: 'share', loadChildren: './share/share.module#SharePageModule' },
  { path: 'help', loadChildren: './help/help.module#HelpPageModule' },
  { path: 'web', loadChildren: './web/web.module#WebPageModule' },
  { path: 'update-profile', loadChildren: './update-profile/update-profile.module#UpdateProfilePageModule' },
  { path: 'share-regis', loadChildren: './share-regis/share-regis.module#ShareRegisPageModule' },
  { path: 'share-register', loadChildren: './share-register/share-register.module#ShareRegisterPageModule' },
  { path: 'news', loadChildren: './news/news.module#NewsPageModule' },
  { path: 'pay-trans/:id', loadChildren: './pay-trans/pay-trans.module#PayTransPageModule' },
  { path: 'contact', loadChildren: './contact/contact.module#ContactPageModule' },
  { path: 'point-report', loadChildren: './point-report/point-report.module#PointReportPageModule' },
  { path: 'report', loadChildren: './report/report.module#ReportPageModule' },
  { path: 'payments-detail/:id', loadChildren: './payments-detail/payments-detail.module#PaymentsDetailPageModule' },
  { path: 'chart', loadChildren: './chart/chart.module#ChartPageModule' },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(public navCtrl: NavController) {

  }

}